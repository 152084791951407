<template>
  <div class="page">
    <div class="home-container">
      <Banner class="banner_class"/>
      <div v-if="featuredEntities.length">
        <Row :title="''" :large="true" :collection-entities="featuredEntity" />
      </div>
      <Row
        v-for="entity in collectionEntities"
        :key="entity.id"
        :type="entity.type"
        :title="entity.title"
        :collection-entities="entity.entities"
      />
    </div>
    <PghModal />
  </div>
</template>

<script>
import Row from '@/components/Row.vue'
import PghModal from '@/components/PghModal.vue'
import { localstorage } from '@/services/storage/localStorageService'
import Banner from '../../components/Banner.vue'

export default {
  name: 'Home',
  head() {
    return {
      title: { inner: 'Watch Exclusive Live Poker Tournaments and TV Shows' },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Stream the world’s largest collection of live poker tournaments and TV shows like the World Series of Poker (WSOP), Poker After Dark, High Stakes Poker, and more on PokerGO.'
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content:
            'Stream the world’s largest collection of live poker tournaments and TV shows like the World Series of Poker (WSOP), Poker After Dark, High Stakes Poker, and more on PokerGO.'
        },
        { hid: 'title', name: 'title', content: 'PokerGO' },
        { hid: 'og:title', name: 'og:title', content: 'PokerGO' },
        {
          hid: 'image',
          name: 'image',
          content:
            (process.env.SITE_URL || 'https://app.pokergo.com') +
            '/logo_white.png'
        },
        {
          hid: 'url',
          name: 'url',
          content: process.env.SITE_URL || 'https://app.pokergo.com'
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: process.env.TITLE || 'PokerGO'
        },
        {
          hid: 'site_name',
          name: 'site_name',
          content: process.env.TITLE || 'PokerGO'
        },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          name: 'google-site-verification',
          content: '83seNIc0yB1_pTXXDaexhtoqHz3jD-IxjcnGV7KTc_U'
        }
      ]
    }
  },
  components: { Banner, Row, PghModal },
  transition: 'fade',
  data() {
    return {
      featuredEntities: [],
      collectionEntities: [],
      favorites: [],
      favoritesData: [],
      tournaments: [
        {
          nameLogo: '../../assets/images/pic1.jpeg'
        },
        {
          nameLogo: '../../assets/images/pic3.jpeg'
        },
        {
          nameLogo: '../../assets/images/pic4.jpeg'
        }
      ]
    }
  },
  computed: {
    featuredEntity() {
      return this.featuredEntities.slice(0, 1)
    }
  },
  async mounted() {
    // reset videoId on Home view
    this.$store.commit('setVideoId', null)
    await this.getData()
    setTimeout(() => {
      localstorage.setWatchTimer(true)
    }, 5 * 60000)
  },
  methods: {
    async loadViewData() {
      const resp = await this.$store.dispatch(
        'home/GetViewData',
        'free-experience'
      )
      if (resp === undefined || resp.data === undefined) {
        console.log('Cannot get home view')
        setTimeout(() => this.loadViewData(), 3000)
        return
      }

      this.featuredEntities = resp.data.data.feature_list
      this.collectionEntities = resp.data.data.collection_list

      // initialize `progress_percent` field in order to detect changes
      this.collectionEntities.forEach((collection) => {
        collection.entities.forEach((entity) => {
          this.$set(entity, 'progress_percent', 0)
        })
      })
    },
    async getData() {
      try {
        await this.loadViewData()
      } catch (err) {
        if (!err.response) {
          console.log(err)
          return
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.home-container {
  margin: 0px 0 60px 0;
  max-width: 100%;
}

.banner_class {
  margin-top: 40px;
  @media (max-width: 971px) {
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
</style>
