<script>
import Main from '../../../components/Marketing/Main.vue'
import FrequentlyAskedQuestion from '../../../components/Marketing/FrequentQuestion.vue'
import RegisterPlan from '../../register/components/RegisterPlan.vue'
import { mapState } from 'vuex'
import { SwiperSlide, Swiper } from 'vue-awesome-swiper'
import ArrowButton from '../../../components/Training/ArrowButton.vue'

export default {
  name: 'Marketing',
  components: {
    Swiper,
    ArrowButton,
    SwiperSlide,
    FrequentlyAskedQuestion,
    RegisterPlan,
    Main
  },
  data() {
    return {
      faq: [
        {
          question: 'What is PokerGO?',
          answer:
            'PokerGO is the #1 poker streaming service in the world, featuring more than 100 days of exclusive live poker tournaments, including the World Series of Poker, Super High Roller Bowl, Poker Masters, U.S. Poker Open, PokerGO Cup, and more. PokerGO is also the home of fan-favorite poker shows, including the iconic High Stakes Poker and the wildly entertaining No Gamble, No Future. PokerGO offers 24/7 on-demand access to the world’s largest library of poker content. With PokerGO, you can stream unlimited shows, livestreams, and exclusive events on your phone, laptop, TV, and tablet.'
        },
        {
          question: 'How much does PokerGO cost?',
          answer:
            'PokerGO offers a variety of subscription tiers, with plans starting at $19.99 per month for the monthly tier. Users have a choice between a monthly plan at $19.99 per month, a three-month plan at $39.99, an annual plan at $99.99, and a PokerGO VIP plan for $299.99 per year that includes a subscription to PokerU. The PokerGO monthly, three-month, and annual subscription plans grant full access to all PokerGO video content. The PokerGO VIP plan includes full access to all PokerGO video content plus access to PokerU video content. All PokerGO subscriptions renew automatically at the end of each term until canceled.'
        },
        {
          question: 'Where can I watch PokerGO?',
          answer:
            'Watch anywhere, anytime with PokerGO. Sign in with your PokerGO account to watch instantly on the web from your personal computer or on any internet-connected device that offers the PokerGO app, including smart TVs, smartphones, tablets, and streaming media players. Watch PokerGO on iPhone, iPad, Apple TV, Android, Amazon Fire TV, and Roku.'
        },
        {
          question: 'How do I cancel?',
          answer:
            'To cancel your PokerGO subscription go to the “My Account” page when signed in and click on cancel subscription. If you’re having trouble, you can email support support@pokergo.com for assistance.'
        },
        {
          question: 'What can I watch on PokerGO?',
          answer:
            'PokerGO is home to the largest library of poker content. Watch as much poker as you want, anytime you want. PokerGO has both tournaments and cash games, including the WSOP, Super High Roller Bowl, Poker Masters, U.S. Poker Open, PokerGO Cup, High Stakes Poker, No Gamble, No Future, and more. PokerGO’s new PokerU offering provides access to leading strategy content to help you improve your game.'
        }
      ],
      newUpcoming: [
        require('@/assets/images/marketing/new-upcoming/WSOP.jpg'),
        require('@/assets/images/marketing/new-upcoming/High Stakes Poker.jpg'),
        require('@/assets/images/marketing/new-upcoming/No Gamble No Future.jpg'),
        require('@/assets/images/marketing/new-upcoming/Super High Roller Bowl.jpg'),
        require('@/assets/images/marketing/new-upcoming/Poker Masters.jpg'),
        require('@/assets/images/marketing/new-upcoming/All In with Negreanu.jpg')
      ],
      daysOfLivePoker: [
        require('@/assets/images/marketing/600x900-tiles/WSOP - 600x900 tile.jpg'),
        require('@/assets/images/marketing/600x900-tiles/Super High Roller Bowl - 600x900 tile.jpg'),
        require('@/assets/images/marketing/600x900-tiles/Poker Masters - 600x900 tile.jpg'),
        require('@/assets/images/marketing/600x900-tiles/US Poker Open - 600x900 tile.jpg'),
        require('@/assets/images/marketing/600x900-tiles/PokerGO Cup - 600x900 tile.jpg'),
        require('@/assets/images/marketing/600x900-tiles/PGT PLO Series - 600x900 tile.jpg')
      ],
      onDemand: [
        require('@/assets/images/marketing/600x900-tiles/High Stakes Poker - 600x900 tile.jpg'),
        require('@/assets/images/marketing/600x900-tiles/No Gamble, No Future - 600x900 tile.jpg'),
        require('@/assets/images/marketing/600x900-tiles/Poker After Dark - 600x900 tile.jpg'),
        require('@/assets/images/marketing/600x900-tiles/Super High Roller Cash Game - 600x900 tile.jpg'),
        require('@/assets/images/marketing/600x900-tiles/High Stakes Duel - 600x900 tile.jpg'),
        require('@/assets/images/marketing/600x900-tiles/WSOP Old School - 600x900 tile.jpg'),
        require("@/assets/images/marketing/600x900-tiles/Dolly's Game - 600x900 tile.jpg"),
        require('@/assets/images/marketing/600x900-tiles/Celebrity Poker Tour - 600x900 tile.jpg')
      ],
      devices: [
        require('@/assets/images/marketing/devices/Android.jpg'),
        require('@/assets/images/marketing/devices/Apple TV.jpg'),
        require('@/assets/images/marketing/devices/Fire TV.jpg'),
        require('@/assets/images/marketing/devices/iPad.jpg'),
        require('@/assets/images/marketing/devices/iPhone.jpg'),
        require('@/assets/images/marketing/devices/Roku.jpg')
      ],
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          300: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 6
          }
        }
      },
      newUpcomingSwiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          300: {
            slidesPerView: 1
          },
          1024: {
            slidesPerView: 3
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      plans: (state) => state.checkout.plans
    })
  },
  mounted() {
    this.$store.dispatch('getPlans', { code: this.$route.query.code })
  },
  methods: {
    selectPlan(plan) {
      this.$store.commit('selectPlan', plan)
      if (this.isAuthenticated) {
        this.$router.push({
          path: this.$route.path + '/' + plan.product_type,
          query: { code: this.$route.query.code }
        })
      } else {
        this.$router.push('/register')
      }
    }
  }
}
</script>

<template>
  <div>
    <Main class="mt-20 md:mt-0" />
    <div class="page" style="margin-top: 0 !important;">
      <div class="w-full">
        <div class="my-10 ">
          <div class="uppercase text-3xl" style="font-family: UniSans-Bold">
            New & upcoming
          </div>

          <div class="mt-2">
            <Swiper :options="newUpcomingSwiperOptions">
              <SwiperSlide
                v-for="(item, index) in newUpcoming"
                :key="`new_upcoming_${index}`"
              >
                <img class="w-full px-1" :src="item" alt="new_upcoming-img" />
              </SwiperSlide>

              <div class="swiper-button-prev" slot="button-prev">
                <ArrowButton direction="left" />
              </div>
              <div class="swiper-button-next" slot="button-next">
                <ArrowButton direction="right" />
              </div>
            </Swiper>
          </div>
        </div>

        <div class="my-10">
          <div class="uppercase text-3xl" style="font-family: UniSans-Bold">
            100+ days of live poker
          </div>
          <div class="mt-1 text-sm">
            Enjoy all of the live poker action you can handle, including
            coverage of the WSOP, Super High Roller Bowl, Poker Masters, U.S.
            Poker Open, PokerGO Cup, and more. PokerGO livestreams more than 100
            days of live tournament action from around the world.
          </div>

          <div class="mt-2">
            <Swiper :options="swiperOptions">
              <SwiperSlide
                v-for="(item, index) in daysOfLivePoker"
                :key="`100-days-of_${index}`"
              >
                <img
                  class="w-full px-1"
                  :src="item"
                  alt="daysOfLivePoker-img"
                />
              </SwiperSlide>

              <div class="swiper-button-prev" slot="button-prev">
                <ArrowButton direction="left" />
              </div>
              <div class="swiper-button-next" slot="button-next">
                <ArrowButton direction="right" />
              </div>
            </Swiper>
          </div>
        </div>

        <div class="my-10">
          <div class="uppercase text-3xl" style="font-family: UniSans-Bold">
            24/7 ON-DEMAND ACCESS
          </div>

          <div class="mt-1 text-sm">
            A PokerGO subscription grants you 24/7 on-demand access to the best
            poker content, including every season of High Stakes Poker and No
            Gamble, No Future, High Stakes Duel, classic WSOP broadcasts dating
            back to 1973, and so much more. Watch poker anywhere, anytime.
          </div>

          <div class="mt-2">
            <Swiper :options="swiperOptions">
              <SwiperSlide
                v-for="(item, index) in onDemand"
                :key="`on-demand_${index}`"
              >
                <img class="w-full px-1" :src="item" alt="on-demand-img" />
              </SwiperSlide>

              <div class="swiper-button-prev" slot="button-prev">
                <ArrowButton direction="left" />
              </div>
              <div class="swiper-button-next" slot="button-next">
                <ArrowButton direction="right" />
              </div>
            </Swiper>
          </div>
        </div>

        <div class="my-10">
          <div
            class="uppercase text-3xl text-center"
            style="font-family: UniSans-Bold"
          >
            Select a plan
          </div>

          <div class="stretch flex flex-col md:flex-row  justify-center">
            <register-plan
              class="mx-auto"
              v-for="(p, i) in plans"
              :plan="p"
              :key="i"
              @selectPlan="selectPlan(p)"
            >
            </register-plan>
          </div>
        </div>

        <div class="my-10">
          <div
            class="uppercase text-3xl text-center"
            style="font-family: UniSans-Bold"
          >
            stream on your favorite devices
          </div>

          <div
            class="grid grid-cols-2 md:grid-cols-3 mt-4 gap-4 md:gap-24 text-2xl text-center"
          >
            <div v-for="(device, i) in devices" :key="`device_${i}`">
              <img class="w-full" :src="device" alt="on-demand-img" />
            </div>
          </div>
        </div>

        <div class="my-10">
          <div class="uppercase text-3xl text-center">
            frequently asked questions
          </div>

          <div class=" mt-4 flex flex-col gap-3">
            <FrequentlyAskedQuestion
              v-for="(item, index) in faq"
              :key="`faq_${index}`"
              :question="item.question"
              :answer="item.answer"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
