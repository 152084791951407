<template>
  <div class="page">
    <div class="home-container">
      <Banner class="banner_class" />
      <div class="poker-cup" v-if="collectionEntities.length">
        <div class="poker-cup__inner" v-if="!isShowFeaturedEntity">
          <div class="poker-cup__inner--desc">
            <h6>{{ featuredEntities[0].subheading }}</h6>
            <h1>{{ featuredEntities[0].title }}</h1>
            <p>
              {{ this.featuredEntities[0].description }}
            </p>
            <div class="btn-watch">
              <a
                href=""
                @click.prevent="handleShowFeatured(featuredEntities[0].id)"
              >
                <i class="fas fa-play"></i> Watch Now!
              </a>
            </div>
          </div>
          <div
            class="poker-cup__inner--img"
            @click.prevent="handleShowFeatured(featuredEntities[0].id)"
          >
            <img
              :src="this.featuredEntities[0].images[0].url"
              alt="Hero image"
            />
          </div>
        </div>
        <Row
          :title="''"
          :large="true"
          :collection-entities="featuredEntity"
          class="single-video underline"
          v-if="isShowFeaturedEntity"
        />
      </div>

      <Row
        v-if="progressCollection.length"
        title="Continue Watching"
        type="collection"
        :collection-entities="progressCollection"
      />
      <Row
        v-for="entity in collectionEntities"
        :key="entity.id"
        :type="entity.type"
        :title="entity.title"
        :collection-entities="entity.entities || []"
      />
    </div>
    <PghModal />
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import Row from '@/components/Row.vue'
import PghModal from '@/components/PghModal.vue'
import Banner from '../../../components/Banner.vue'

export default {
  name: 'Home',
  head() {
    return {
      title: { inner: 'Watch Exclusive Live Poker Tournaments and TV Shows' },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Stream the world’s largest collection of live poker tournaments and TV shows like the World Series of Poker (WSOP), Poker After Dark, High Stakes Poker, and more on PokerGO.'
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content:
            'Stream the world’s largest collection of live poker tournaments and TV shows like the World Series of Poker (WSOP), Poker After Dark, High Stakes Poker, and more on PokerGO.'
        },
        { hid: 'title', name: 'title', content: 'PokerGO' },
        { hid: 'og:title', name: 'og:title', content: 'PokerGO' },
        {
          hid: 'image',
          name: 'image',
          content:
            (process.env.SITE_URL || 'https://app.pokergo.com') +
            '/logo_white.png'
        },
        {
          hid: 'url',
          name: 'url',
          content: process.env.SITE_URL || 'https://app.pokergo.com'
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: process.env.TITLE || 'PokerGO'
        },
        {
          hid: 'site_name',
          name: 'site_name',
          content: process.env.TITLE || 'PokerGO'
        },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          name: 'google-site-verification',
          content: '83seNIc0yB1_pTXXDaexhtoqHz3jD-IxjcnGV7KTc_U'
        }
      ]
    }
  },
  components: { Banner, Row, PghModal },
  transition: 'fade',
  data() {
    return {
      featuredEntities: [],
      collectionEntities: [],
      favorites: [],
      progressCollection: {},
      favoritesData: [],
      tournaments: [
        {
          nameLogo: '../../assets/images/pic1.jpeg'
        },
        {
          nameLogo: '../../assets/images/pic3.jpeg'
        },
        {
          nameLogo: '../../assets/images/pic4.jpeg'
        }
      ],
      isShowFeaturedEntity: false
    }
  },
  computed: {
    featuredEntity() {
      return this.featuredEntities.slice(0, 1)
    },
    viewData() {
      let views = this.getViews()
      for (let i = 0; i < views.length; i++) {
        if (views[i].route === this.$route.path) {
          return views[i]
        }
      }
      return views ? views[0] : null
    },
    customHeight() {
      if (this.isShowFeaturedEntity) {
        return 'tournaments-box noHeight'
      } else {
        return 'tournaments-box'
      }
    }
  },
  async mounted() {
    // reset videoId on Home view
    this.$store.commit('setVideoId', null)

    await this.getData()
    this.favoritesData =
      this.favorites.length === 0 ? this.user.favorites || [] : this.favorites
  },
  methods: {
    ...mapGetters(['isAuthenticated', 'user', 'getViews']),
    ...mapActions(['FETCH_ME']),
    async loadSubscriberData() {
      const respFav = await this.$store.dispatch('home/GetSubscriberFavorites')

      const favoriteVideos = respFav.data?.data.videos?.videos || []
      favoriteVideos.forEach((favorite) => (favorite.entity_type = 'video'))
      const favoriteCollections = respFav.data?.data.collections || []
      favoriteCollections.forEach(
        (favorite) => (favorite.entity_type = 'collection')
      )
      const entities = [...favoriteVideos, ...favoriteCollections]

      const tmp = []
      entities.forEach((entity) => {
        tmp.push({
          id: entity.id,
          title: entity.title,
          type: entity.entity_type,
          images: entity.images,
          entitled: entity.entitled,
          progress: 0,
          duration: 0,
          airDate: entity.aired_at,
          video_type: entity.type
        })
      })
      this.favorites = tmp
    },
    async loadViewData() {
      const resp = await this.$store.dispatch(
        'home/GetViewData',
        this.viewData.name
      )
      if (resp === undefined || resp.data === undefined) {
        console.log('Cannot get home view')
        setTimeout(() => this.loadViewData(), 3000)
        return
      }

      this.featuredEntities = resp.data.data.feature_list
      this.collectionEntities = resp.data.data.collection_list
      // initialize `progress_percent` field in order to detect changes
      this.collectionEntities.forEach((collection) => {
        collection.entities &&
          collection.entities.forEach((entity) => {
            this.$set(entity, 'progress_percent', 0)
          })
      })
    },
    loadProgressData() {
      return this.$store.dispatch('home/GetProgress')
    },
    async getData() {
      if (this.viewData === undefined) {
        this.$error({ message: 'View not found' })
      }
      try {
        await this.loadViewData()
      } catch (err) {
        if (!err.response) {
          console.log(err)
          return
        }
      }

      if (this.$store.state.user.authenticated) {
        await this.loadSubscriberData()

        // load recent videos with progress if user is logged in
        this.loadProgressData()
          .then(({ data }) => {
            if (data.data.total) {
              // save progressVideos to vuex
              const progressVideos = data.data.videos
              this.$store.commit('setProgressVideos', progressVideos)

              // iterate `collection.entities` and add `progress_percent` data
              this.collectionEntities.forEach((collection) => {
                collection.entities &&
                  collection.entities.forEach((entity) => {
                    const filteredVideo = progressVideos.filter(
                      (video) => video.id === entity.id
                    )
                    if (filteredVideo.length)
                      entity['progress_percent'] =
                        filteredVideo[0].progress_percent
                  })
              })

              // filter non-complete videos and sort according to `last_watched`
              this.progressCollection = progressVideos
                .filter((video) => !video.completed)
                .sort((a, b) => {
                  return moment(b.last_watched).diff(moment(a.last_watched))
                })
            }
          })
          .catch((err) => {
            if (!err.response) {
              console.log(err)
              return
            }
          })
      }
    },
    handleShowFeatured(id) {
      // if (this.isAuthenticated) {
      //   this.isShowFeaturedEntity = true
      // } else {
      //   this.isShowFeaturedEntity = false
      // }
      this.$router.push('/videos/' + id)
    }
  }
}
</script>
<style scoped lang="scss">
.home-container {
  margin: 0 0 60px 0;
  max-width: 100%;
  .poker-cup {
    // background: url("../../assets/images/hero-img.png") no-repeat;
    // background-size: center;
    // -height: 900px;min
    position: relative;
    &__inner {
      display: flex;
      justify-content: space-between;
      margin-top: 4%;
      margin-left: 10px;

      &--desc {
        width: 40%;
        h6 {
          color: #b22aca;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 16px;
          font-family: 'UniSans-SemiBold';
          text-transform: uppercase;
        }
        h1 {
          color: #ffffff;
          font-size: 28px;
          letter-spacing: 0;
          line-height: 34px;
          font-family: 'UniSans-SemiBold';
        }
        .event {
          margin: 25px 0px 25px 0px;
          a {
            padding: 12px 25px;
            opacity: 0.5;
            color: #ffffff;
            font-family: 'UniSans-SemiBold';
            font-size: 12px;
            letter-spacing: 0;
            border: 1px solid #ffffff;
            opacity: 0.25;
            border-radius: 14.5px;
          }
        }
        p {
          color: #ffffff;
          font-family: 'UniSans-SemiBold';
          font-size: 15px;
          letter-spacing: 0;
          line-height: 24px;
          margin-bottom: 40px;
        }
        .btn-watch {
          width: calc(100% - 30%);
          a {
            padding: 20px 20px;
            border: 2px solid #ffffff;
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.1);
            box-shadow: inset 0 0 12px 4px #9f26b5, 0 0 8px 4px #9f26b5;
            border-radius: 4px;
            color: #ffffff;
            font-family: 'UniSans-SemiBold';
            font-size: 14px;
          }
        }
        .btn-watch:hover {
          transition: 0.2s ease-in;
          a {
            background: #821f94;
          }
        }
      }
      &--img {
        width: 50%;
        cursor: pointer;

        img {
          width: 100%;
        }
      }
    }

    .tournaments-box {
      margin-top: 20%;
      width: 100%;
      .list-tournaments {
        display: flex;
        margin-top: 3rem;
        justify-content: space-around;
        flex-wrap: wrap;
      }
      h1 {
        font-size: 20px;
        text-transform: uppercase;
        font-family: 'UniSans-Bold-Italic';
        line-height: 2em;
        left: 1.5rem;
      }
    }
    .noHeight {
      margin-top: 0%;
    }
  }
}
.single-video {
  margin-top: 20px;
}
.underline {
  border-bottom: 1px solid #5b5b5b;
  margin-bottom: 1rem;
}
@media screen and (max-width: 800px) {
  .single-video {
    margin-top: -10px;
  }

  .home-container {
    .poker-cup {
      min-height: 400px;

      &__inner {
        &--desc {
          width: 100%;
        }
        &--img {
          display: none;
        }
      }
      .list-tournaments {
        margin-bottom: 2rem;
        .tournaments a img {
          width: 100px;
        }
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .btn-watch {
    width: calc(100% - 0%) !important;
  }
}

.banner_class {
  margin-top: 40px;
  @media (max-width: 971px) {
    margin-top: 0px;
  }
}
</style>
