import api from '@/api'
import { upgradablePlans } from '@/library/config/plans'

export function setDocumentMetaData(to, from, next) {
  if (to.name) {
    document.title = to.name + ' | PokerGO'
  }
  if (to.meta.description) {
    let metaDesc = document.querySelector('head meta[name="description"]')
    metaDesc.setAttribute('content', to.meta.description)
    metaDesc = document.querySelector('head meta[property="og:description"]')
    metaDesc.setAttribute('content', to.meta.description)
  }

  // Check trackdesk query check
  if (to.query.linkId && to.query.sourceId && to.query.tenantId) {
    const trackdeskQuery = {
      link_id: to.query.linkId,
      tenant_id: to.query.tenantId,
      source_id: to.query.sourceId
    }
    localStorage.setItem('trackdesk', JSON.stringify(trackdeskQuery))
  }
}

export async function handlePromoCodeQuery(to, from, next, store) {
  let runNextMiddleware = true
  const promoCodePersistentPaths = [
    'Select a Plan',
    'Create an Account',
    'Login',
    'Verify Email',
    'Payment Method',
    'Upgrade',
    'UpgradeComplete'
  ]

  if (to.query.code) {
    const { data } = await api.checkout.getPromocodeInformation({
      code: to.query.code
    })

    let isSubscriber = store.getters.isSubscriber
    let subscription = store.state.user.subscription

    const promoCodeInterval = data.data.Interval

    if (promoCodeInterval) {
      store.commit('setPromoCodeDetails', data.data)
      if (!isSubscriber) {
        next({
          path: '/select-plan',
          query: { plan: promoCodeInterval }
        })
        runNextMiddleware = false
      } else if (
        upgradablePlans[subscription.interval].includes(promoCodeInterval)
      ) {
        next({
          path: `/account/change-plan/checkout`,
          query: { plan: promoCodeInterval }
        })
        runNextMiddleware = false
      }
    }
  } else if (!promoCodePersistentPaths.includes(to.name)) {
    store.commit('resetPromoCodeDetails')
  }
  return runNextMiddleware
}

export function handleAuthentication(to, from, next, store) {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let isAuthed = store.getters.isAuthenticated
    if (!isAuthed) {
      localStorage.setItem('loginRedirectURL', to.fullPath)
      next({
        path: to.name === 'Home' ? '/' : 'login'
      })
    } else {
      next()
    }
  } else {
    next()
  }
}
