var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      [
        _c("Banner", { staticClass: "banner_class" }),
        _c("div", { staticClass: "controls" }, [
          _c("div", { staticClass: "two-buttons" }, [
            _c(
              "button",
              {
                class: { active: _vm.viewType === "grid" },
                on: {
                  click: function($event) {
                    return _vm.setView("grid")
                  }
                }
              },
              [
                _c("div", { staticClass: "icon grid" }),
                _c("b", [_vm._v("GRID")])
              ]
            ),
            _c(
              "button",
              {
                class: { active: _vm.viewType === "list" },
                on: {
                  click: function($event) {
                    return _vm.setView("list")
                  }
                }
              },
              [
                _c("div", { staticClass: "icon list" }),
                _c("b", [_vm._v("LIST")])
              ]
            )
          ]),
          _vm.viewType === "grid" ? _c("span", [_vm._v("SORT BY")]) : _vm._e(),
          _vm.viewType === "grid"
            ? _c("div", { staticClass: "two-buttons" }, [
                _c(
                  "button",
                  {
                    class: { active: _vm.gridSort === "default" },
                    on: {
                      click: function($event) {
                        _vm.gridSort = "default"
                      }
                    }
                  },
                  [_c("b", [_vm._v("POPULAR")])]
                ),
                _c(
                  "button",
                  {
                    class: { active: _vm.gridSort === "a-z" },
                    on: {
                      click: function($event) {
                        _vm.gridSort = "a-z"
                      }
                    }
                  },
                  [_c("b", [_vm._v("A-Z")])]
                )
              ])
            : _vm._e()
        ]),
        _vm.viewType === "list"
          ? _c(
              "div",
              { staticClass: "list-container" },
              _vm._l(_vm.collections, function(entity) {
                return _c("Row", {
                  key: entity.id,
                  attrs: {
                    type: entity.type,
                    title: entity.title,
                    collectionID: entity.id,
                    "collection-entities": entity.entities
                  }
                })
              }),
              1
            )
          : _vm._e(),
        _vm.viewType === "grid"
          ? _c(
              "div",
              { staticClass: "grid-container" },
              _vm._l(_vm.sortedEntities, function(entity, idx) {
                return _c("small-card", {
                  key: idx + entity.id,
                  staticStyle: { position: "relative" },
                  attrs: {
                    "entity-id": entity.id,
                    "entity-type": entity.type,
                    "entity-data": entity.images ? entity : undefined,
                    "show-title": true,
                    "no-reload": true
                  }
                })
              }),
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }