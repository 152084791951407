<template>
  <div class="footer" :class="{ 'pb-1': isMobile }">
    <div v-if="!isMobile" class="footer-row">
      <div class="footer-links">
        <div class="footer-navlinks">
          <ul v-for="(category, index) in footerLinks" :key="index">
            <p class="category-header">{{ category.text }}</p>
            <li
              v-for="(subLink, indexSubLink) in category.subLinks"
              :key="indexSubLink"
              class="un-numbered-list"
            >
              <a v-if="subLink.blanc" :href="subLink.linkTo" target="_blank">
                {{ subLink.text }}
              </a>
              <router-link v-else :to="subLink.linkTo">
                {{ subLink.text }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="footer-downloads">
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/pokergo-stream-poker-tv/id1235783484"
          >
            <img
              class="footer-img"
              src="@/assets/images/download-applestore.png"
            />
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.pokercentral.poker&hl=en_US&gl=US"
          >
            <img
              class="footer-img"
              src="@/assets/images/download-googleplay.png"
            />
          </a>
        </div>
      </div>

      <div class="footer-logos">
        <router-link to="/">
          <img class="footer-img" src="@/assets/images/logo.png" />
        </router-link>

        <div class="footer-social">
          <a
            v-for="(socialIcon, indexSocial) in socialIcons"
            :key="indexSocial"
            :href="socialIcon.link_address"
            target="_blank"
            class="imgicon-container"
          >
            <i :class="socialIcon.icon" class="imgicon" />
          </a>
        </div>

        <div class="copyright">
          &copy;{{ year }} PokerGO&reg;. All Rights Reserved.
        </div>
      </div>
    </div>
    <client-only>
      <cookie-law theme="pokergo"></cookie-law>
    </client-only>
  </div>
</template>
<script>
import CookieLaw from 'vue-cookie-law'
import ClientOnly from 'vue-client-only'
export default {
  name: 'Footer',
  components: { CookieLaw, ClientOnly },
  data() {
    return {
      socialIcons: {
        facebook: {
          icon: 'fab fa-facebook-f',
          link_address: 'https://www.facebook.com/pokergo'
        },
        twitter: {
          icon: 'fab fa-twitter',
          link_address: 'https://twitter.com/pokergo'
        },
        instagram: {
          icon: 'fab fa-instagram',
          link_address: 'https://www.instagram.com/pokergo/'
        },
        youtube: {
          icon: 'fab fa-youtube',
          link_address:
            'https://www.youtube.com/channel/UCOPw3R-TUUNqgN2bQyidW2w'
        },
        discord: {
          icon: 'fab fa-discord',
          link_address: 'https://discord.gg/pokergo'
        }
      },
      footerLinks: {
        about: {
          text: 'About',
          linkTo: '/about',
          subLinks: {
            aboutpokergo: {
              text: 'About PokerGO',
              linkTo: '/about'
            },
            pressreleases: {
              blanc: true,
              text: 'Press Releases',
              linkTo: 'https://www.pokergonews.com/press-releases/'
            }
          }
        },
        support: {
          text: 'Support',
          linkTo: '/about',
          subLinks: {
            faq: {
              text: 'FAQ',
              linkTo: '/faq'
            },
            contact: {
              text: 'Contact',
              linkTo: '/faq#contact'
            }
          }
        },
        legal: {
          text: 'Legal',
          linkTo: '/about',
          subLinks: {
            privacypolicy: {
              text: 'Privacy Policy',
              linkTo: '/privacy-policy'
            },
            Contact: {
              text: 'Terms and Conditions',
              linkTo: '/terms-and-conditions'
            }
          }
        }
      }
    }
  },
  computed: {
    year() {
      var d = new Date()
      return d.getFullYear()
    },
    isMobile() {
      return window.innerWidth <= 1024
    }
  }
}
</script>
<style lang="scss">
.footer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: 100%;
  padding: $shrink-fit;
  bottom: 0;
  border-top: 1px solid #5b5b5b;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: $poker-background-color;

  @media (max-width: 1150px) {
    border-top: none;
    height: auto;
  }
  @media (max-width: 480px) {
    padding: 32px 0;
    font-size: 14px;
  }
  .footer-row {
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: space-around;
    padding: 0;

    .footer-links {
      order: 2;

      .footer-navlinks {
        display: flex;
        padding-top: 20px;
        ul {
          width: 180px;
          padding-left: 0;
        }
        .category-header {
          text-transform: uppercase;
          font-weight: 700;
        }
        @media (max-width: 550px) {
          max-width: 100%;
          ul {
            width: 121px;
          }
        }

        .un-numbered-list {
          list-style: none;
          line-height: 2rem;
          @media (max-width: 768px) {
            margin: 1rem 0;
            line-height: 1.2rem;
          }
          * {
            color: #ffffff;
            &:hover {
              color: $poker-accent;
            }
          }
        }
      }

      .footer-downloads {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        height: 80px;
        width: min(350px, 100vw);

        @media (max-width: 550px) {
          height: 80px;
        }
        a {
          margin-right: 20px;
          margin-top: 20px;
        }

        .footer-img {
          width: 160px;
        }
      }
    }

    .footer-logos {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 180px;
      justify-content: space-evenly;
      order: 1;

      @media (max-width: 1150px) {
        align-items: center;
        padding: 0;
      }

      .footer-social {
        width: 80%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        padding-left: 12px;

        @media (max-width: 768px) {
          margin-bottom: 0;
          width: 100%;
        }
      }
      .imgicon-container {
        height: 1.5rem;
        width: 1.5rem;

        .imgicon {
          font-size: 20px;
          width: auto;
          color: white;

          &:hover {
            color: $poker-accent;
          }
        }
      }
      .copyright {
        color: #999999;
        text-align: start;
        width: 315px;
        padding-left: 12px;
        @media (max-width: 1150px) {
          padding-bottom: 35px;
          text-align: center;
          padding-left: 0px;
        }
      }
    }

    @media (max-width: 1150px) {
      flex-flow: column;
      align-items: center;

      .footer-links {
        order: 1;
      }

      .footer-logos {
        order: 2;
      }
    }
  }
}

.pb-60 {
  padding-bottom: 60px;
  border-top: none;
}

.Cookie--bottom {
  bottom: 0;
}
.Cookie--pokergo {
  padding: 1.25em;
  color: $poker-primary;
  background: #424851;

  @media (max-width: 420px) {
    padding-top: 1px;
    padding-bottom: 1px;
  }
}

.Cookie--pokergo .Cookie__button {
  padding: 0.625em 3.125em;
  font-size: 1em;
  border: 2px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 12px 4px #9f26b5, 0 0 8px 4px #9f26b5;
}

.Cookie--pokergo .Cookie__button:hover {
  color: #ffffff;
  background-color: $poker-accent !important;
}

@media (min-width: 768px) {
  .Cookie--pokergo .Cookie__button {
    margin-right: 140px;
  }
}
@media (max-width: 768px) {
  .Cookie--bottom {
    bottom: 60px !important;
  }
}
</style>
