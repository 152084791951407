<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Banner',
  data() {
    const numberOfBanners = 5
    return {
      bannerIndex: Math.floor(Math.random() * numberOfBanners),
      deviceType: 'web',
      banners: [
        {
          file: 'vip-blue.jpg',
          link: '/register/select-plan'
        },
        {
          file: 'vip-orange.jpg',
          link: '/register/select-plan'
        },
        {
          file: 'vip-pink.jpg',
          link: '/register/select-plan'
        },
        {
          file: 'HSP.jpg',
          link: '/collections/d3f8f884-8395-4c19-b463-77f711f41b0d'
        },
        {
          file: 'NGNF.jpg',
          link: '/collections/778ce434-adcb-4362-9025-06120c45b506'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['isSubscriber']),
    currentBannerLink() {
      return this.banners[this.bannerIndex].link
    },
    currentBannerImage() {
      return require(`@/assets/images/banners/${this.deviceType}/${
        this.banners[this.bannerIndex].file
      }`)
    }
  },
  created() {
    this.updateImage()
    window.addEventListener('resize', this.updateImage)
  },
  destroyed() {
    window.removeEventListener('resize', this.updateImage)
  },
  methods: {
    updateImage() {
      if (window.innerWidth < 971) {
        this.deviceType = 'mobile'
      } else {
        this.deviceType = 'web'
      }
    }
  }
}
</script>

<template>
  <div class="banner_container" v-if="!isSubscriber">
    <router-link :to="currentBannerLink">
      <img :src="currentBannerImage" alt="top_banner" />
    </router-link>
  </div>
</template>

<style scoped lang="scss">
.banner_container {
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    width: 100%;
  }
}

@media (min-width: 971px) {
  .banner_container {
    img {
      width: 970px;
    }
  }
}
</style>
