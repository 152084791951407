import api from '@/api'
const { search } = api
export default {
  async GetPopularSearch(state, payload) {
    let response = await search.getPopularSearch(payload)
    if (response.status === 200) {
      const payload = response.data.data.map((el) => el.name)
      state.commit('setPopularSearches', payload)
    }

    return response
  },
  async SearchQuery(state, payload) {
    let response = await search.searchQuery(payload)
    if (response.status === 200) {
      console.log('searchQuery', response)
    }
    return response
  }
}
