var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "home-container" },
        [
          _c("Banner", { staticClass: "banner_class" }),
          _vm.collectionEntities.length
            ? _c(
                "div",
                { staticClass: "poker-cup" },
                [
                  !_vm.isShowFeaturedEntity
                    ? _c("div", { staticClass: "poker-cup__inner" }, [
                        _c("div", { staticClass: "poker-cup__inner--desc" }, [
                          _c("h6", [
                            _vm._v(_vm._s(_vm.featuredEntities[0].subheading))
                          ]),
                          _c("h1", [
                            _vm._v(_vm._s(_vm.featuredEntities[0].title))
                          ]),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(this.featuredEntities[0].description) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "btn-watch" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.handleShowFeatured(
                                      _vm.featuredEntities[0].id
                                    )
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-play" }),
                                _vm._v(" Watch Now! ")
                              ]
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "poker-cup__inner--img",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.handleShowFeatured(
                                  _vm.featuredEntities[0].id
                                )
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: this.featuredEntities[0].images[0].url,
                                alt: "Hero image"
                              }
                            })
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.isShowFeaturedEntity
                    ? _c("Row", {
                        staticClass: "single-video underline",
                        attrs: {
                          title: "",
                          large: true,
                          "collection-entities": _vm.featuredEntity
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.progressCollection.length
            ? _c("Row", {
                attrs: {
                  title: "Continue Watching",
                  type: "collection",
                  "collection-entities": _vm.progressCollection
                }
              })
            : _vm._e(),
          _vm._l(_vm.collectionEntities, function(entity) {
            return _c("Row", {
              key: entity.id,
              attrs: {
                type: entity.type,
                title: entity.title,
                "collection-entities": entity.entities || []
              }
            })
          })
        ],
        2
      ),
      _c("PghModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }