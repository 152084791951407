<template>
  <div
    v-show="visible"
    @click="navigate(false)"
    :class="{
      'large-card': large,
      'small-card': type === 'small',
      'event-card': type === 'event'
    }"
    class="position-relative"
  >
    <div class="video-card" v-if="type !== 'event'">
      <div
        v-if="showPlaceholder || !showVideoPlayer"
        class="position-relative cursor-pointer"
      >
        <img
          :src="overlayImage"
          @error="error"
          @load="imageLoaded"
          class="image"
        />
        <img
          v-show="showPlaceholder"
          @load="imageLoaded"
          src="@/assets/images/pgoplaceholder-sm.png"
          class="image"
        />
        <!-- progress bar -->
        <i v-if="progress" class="progress-bar-bg" />
        <i
          v-if="progress"
          :style="{ width: progressPercent }"
          class="progress-bar"
        />
        <!---->
      </div>
      <div v-if="showVideoPlayer" class="position-relative cursor-pointer">
        <video-player
          :id="entityId"
          :key="$route.fullPath"
          :overlay-image="overlayImage"
          :autoplay="false"
        />
        <!-- progress bar -->
        <i v-if="progress" class="progress-bar-bg" />
        <i
          v-if="progress"
          :style="{ width: progressPercent }"
          class="progress-bar"
        />
        <!---->
      </div>
      <div v-if="type !== 'large'" class="infor">
        <div :class="['infor-left']">
          <div
            @click.stop="navigate(true)"
            v-if="showTitle"
            class="title"
            :class="{ overflow: true }"
          >
            {{ title }}
          </div>
          <div v-if="showSubText" class="flex gap-2 text-sm opacity-60">
            <div v-if="author">By {{ author }}</div>
            <div v-if="duration">
              ({{
                Math.floor(moment.duration(duration, 'seconds').asMinutes())
              }}
              min)
            </div>
          </div>
        </div>
        <div class="infor-right" v-if="!entitled">
          <img
            class="locked-img"
            src="@/assets/images/lock-solid.svg"
            title="A subscription is required to view this video."
          />
        </div>
      </div>
      <small v-if="type === 'large'" class="title">
        {{ title }}
      </small>
      <br />
    </div>
    <div
      class="video-card"
      v-else
      :style="{ backgroundImage: 'url(' + overlayImage + ')' }"
    >
      <div class="after"></div>
      <small @click.stop="navigate(true)" v-if="showTitle" class="title">
        {{ title }}
      </small>
      <br />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import VideoPlayer from '../components/VideoPlayer.vue'
import { General } from '@/library/general'
import moment from 'moment'

export default {
  name: 'SmallCard',
  components: {
    VideoPlayer
  },
  props: {
    entityType: {
      required: true,
      type: String
    },
    entityId: {
      required: true,
      type: String
    },
    visible: {
      type: Boolean,
      default: () => true
    },
    type: {
      type: String,
      default: 'small'
    },
    video: {
      type: Boolean,
      default: () => true
    },
    entityData: {
      type: Object,
      default: () => {}
    }, // this should be {images: []Image; title: string; progress: number, entityType: 'video'|'collection'}
    showTitle: {
      required: false,
      type: Boolean,
      default: true
    },
    noReload: {
      required: false,
      type: Boolean,
      default: false
    },
    author: {
      required: false,
      type: String,
      default: ''
    },
    duration: {
      required: false,
      type: Number,
      default: 0
    },
    showSubText: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      title: '',
      showName: '',
      season: '',
      episode: '',
      tagVisible: false,
      date: '',
      images: [],
      pre_air_images: [],
      air_date: '',
      progress: 0,
      sourceLength: 0,
      entitled: true,
      showPlaceholder: true,
      hideVideoPlayer: false,
      isThumbnail: false
    }
  },
  watch: {
    entityData: {
      handler: function(val) {
        this.progress = val.progress_percent
      },
      deep: true
    }
  },
  computed: {
    moment() {
      return moment
    },
    ...mapGetters(['getVideoProgressPercent', 'isSubscriber']),
    debugging() {
      return '**' + this.entityId + ' - ' + this.entityType
    },
    large() {
      return this.type === 'large'
    },
    trackingProgress() {
      return process.env.TRACKING_PROGRESS === 'true'
    },
    progressPercent() {
      return String(this.progress) + '%'
    },
    showVideoPlayer() {
      return (
        this.large && this.video && this.entityType === 'video' && this.entitled
      )
    },
    overlayImage() {
      return General.getOverlayImage(
        this.air_date,
        this.pre_air_images,
        this.images
      )
    }
  },
  mounted() {
    if (this.entityData && this.entityData.images) {
      this.title = this.entityData.title
      if (this.entityData.show_tags) {
        var showTags = this.entityData.show_tags.filter(
          (tag) => tag.tag.tag_type === 'show'
        )
        if (showTags.length) {
          this.tagVisible = true
          this.showName = showTags[0].tag.name
          this.season = 'S' + showTags[0].season
          this.episode = 'E' + showTags[0].episode_number
        }
      }
      this.entityType = this.entityData.type
      this.images = this.entityData.images
      if (this.entityData.pre_air_images) {
        this.pre_air_images = this.entityData.pre_air_images
      }

      this.air_date = this.entityData.air_date

      this.progress = this.entityData.progress_percent
        ? this.entityData.progress_percent
        : this.getVideoProgressPercent(this.entityId)

      this.entitled = this.entityData.entitled
        ? this.entityData.entitled
        : this.isSubscriber

      const type = this.entityData.video_type
      const now = new Date()
      const airDate = new Date(this.entityData.air_date)
      if (now < airDate) {
        this.hideVideoPlayer = true
      } else {
        this.hideVideoPlayer = false
      }

      // Large cards can show videos and need to load extra data
      if (!this.large) {
        return
      }
    }
  },
  methods: {
    error(e) {
      console.log('smallcard error')
      if (this.isThumbnail) {
        return
      }
      e.target.src = this.thumbnailImage
      this.isThumbnail = true
    },
    navigate(forceNavigate) {
      if (!forceNavigate && this.showVideoPlayer) {
        return
      }

      if (this.entityType === 'video' || this.entityType === 'vod') {
        this.$router.push('/videos/' + this.entityId)
      }

      if (this.entityType === 'collection') {
        let path = '/collections/' + this.entityId
        //During a click already on a collection page we need to make sure we reload the collection data or page won't load

        this.$emit('updatedata', this.entityId)
        this.$router.push(path)
      }
    },
    imageLoaded() {
      this.showPlaceholder = false
    }
  }
}
</script>
<style lang="scss" scoped>
.infor {
  display: flex;
  padding-top: 10px;
  justify-content: space-between;
  .infor-left {
    .title {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.overflow {
        white-space: unset;
      }
    }
    .group {
      font-size: 12px;
      color: $light-gray;
      text-transform: capitalize;
      .fas {
        margin-right: 5px;
        &.fa-circle {
          font-size: 5px;
          margin: 0px 10px;
        }
      }
    }
  }
  .infor-right {
    margin-left: 10px;
    .locked-img {
      width: 15px;
      right: 20px;
    }
  }
}
.position-relative {
  position: relative !important;
}
.progress-bar-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: #535353;
  width: 100%;
}
.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: $accent-color;
}

.small-card,
.event-card {
  flex: 0 0 25%;
  width: 100%;
  max-width: 25%;
  height: 100%;
  padding-right: 15px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    opacity: 0.75;
  }
  @media (max-width: 768px) {
    flex: 0 0 33%;
    max-width: 33%;
  }
  @media (max-width: 480px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .image {
    width: 100%;
  }
  .video-card {
    height: 100%;
  }
  .title {
    font-size: 1rem;
    font-weight: bold;
    width: 100%;
  }
}
.event-card {
  .video-card {
    height: 220px;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    @media (max-width: 768px) {
      height: 180px;
    }
  }
  .title {
    padding: 10px;
  }
}
.locked {
  position: absolute;
  bottom: 15px;
  left: 10px;
  width: 25px;
}
.large-card {
  width: 80%;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
  .image {
    width: 100%;
  }
  .video-card {
    height: 100%;
  }
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
    @media (max-width: 768px) {
      justify-content: flex-start;
      padding-left: 10px;
      font-size: 1.3rem;
    }
  }
}
</style>
