var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Main", { staticClass: "mt-20 md:mt-0" }),
      _c(
        "div",
        { staticClass: "page", staticStyle: { "margin-top": "0 !important" } },
        [
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "my-10 " }, [
              _c(
                "div",
                {
                  staticClass: "uppercase text-3xl",
                  staticStyle: { "font-family": "UniSans-Bold" }
                },
                [_vm._v(" New & upcoming ")]
              ),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c(
                    "Swiper",
                    { attrs: { options: _vm.newUpcomingSwiperOptions } },
                    [
                      _vm._l(_vm.newUpcoming, function(item, index) {
                        return _c(
                          "SwiperSlide",
                          { key: "new_upcoming_" + index },
                          [
                            _c("img", {
                              staticClass: "w-full px-1",
                              attrs: { src: item, alt: "new_upcoming-img" }
                            })
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "swiper-button-prev",
                          attrs: { slot: "button-prev" },
                          slot: "button-prev"
                        },
                        [_c("ArrowButton", { attrs: { direction: "left" } })],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "swiper-button-next",
                          attrs: { slot: "button-next" },
                          slot: "button-next"
                        },
                        [_c("ArrowButton", { attrs: { direction: "right" } })],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "my-10" }, [
              _c(
                "div",
                {
                  staticClass: "uppercase text-3xl",
                  staticStyle: { "font-family": "UniSans-Bold" }
                },
                [_vm._v(" 100+ days of live poker ")]
              ),
              _c("div", { staticClass: "mt-1 text-sm" }, [
                _vm._v(
                  " Enjoy all of the live poker action you can handle, including coverage of the WSOP, Super High Roller Bowl, Poker Masters, U.S. Poker Open, PokerGO Cup, and more. PokerGO livestreams more than 100 days of live tournament action from around the world. "
                )
              ]),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c(
                    "Swiper",
                    { attrs: { options: _vm.swiperOptions } },
                    [
                      _vm._l(_vm.daysOfLivePoker, function(item, index) {
                        return _c(
                          "SwiperSlide",
                          { key: "100-days-of_" + index },
                          [
                            _c("img", {
                              staticClass: "w-full px-1",
                              attrs: { src: item, alt: "daysOfLivePoker-img" }
                            })
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "swiper-button-prev",
                          attrs: { slot: "button-prev" },
                          slot: "button-prev"
                        },
                        [_c("ArrowButton", { attrs: { direction: "left" } })],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "swiper-button-next",
                          attrs: { slot: "button-next" },
                          slot: "button-next"
                        },
                        [_c("ArrowButton", { attrs: { direction: "right" } })],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "my-10" }, [
              _c(
                "div",
                {
                  staticClass: "uppercase text-3xl",
                  staticStyle: { "font-family": "UniSans-Bold" }
                },
                [_vm._v(" 24/7 ON-DEMAND ACCESS ")]
              ),
              _c("div", { staticClass: "mt-1 text-sm" }, [
                _vm._v(
                  " A PokerGO subscription grants you 24/7 on-demand access to the best poker content, including every season of High Stakes Poker and No Gamble, No Future, High Stakes Duel, classic WSOP broadcasts dating back to 1973, and so much more. Watch poker anywhere, anytime. "
                )
              ]),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c(
                    "Swiper",
                    { attrs: { options: _vm.swiperOptions } },
                    [
                      _vm._l(_vm.onDemand, function(item, index) {
                        return _c(
                          "SwiperSlide",
                          { key: "on-demand_" + index },
                          [
                            _c("img", {
                              staticClass: "w-full px-1",
                              attrs: { src: item, alt: "on-demand-img" }
                            })
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "swiper-button-prev",
                          attrs: { slot: "button-prev" },
                          slot: "button-prev"
                        },
                        [_c("ArrowButton", { attrs: { direction: "left" } })],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "swiper-button-next",
                          attrs: { slot: "button-next" },
                          slot: "button-next"
                        },
                        [_c("ArrowButton", { attrs: { direction: "right" } })],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "my-10" }, [
              _c(
                "div",
                {
                  staticClass: "uppercase text-3xl text-center",
                  staticStyle: { "font-family": "UniSans-Bold" }
                },
                [_vm._v(" Select a plan ")]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "stretch flex flex-col md:flex-row  justify-center"
                },
                _vm._l(_vm.plans, function(p, i) {
                  return _c("register-plan", {
                    key: i,
                    staticClass: "mx-auto",
                    attrs: { plan: p },
                    on: {
                      selectPlan: function($event) {
                        return _vm.selectPlan(p)
                      }
                    }
                  })
                }),
                1
              )
            ]),
            _c("div", { staticClass: "my-10" }, [
              _c(
                "div",
                {
                  staticClass: "uppercase text-3xl text-center",
                  staticStyle: { "font-family": "UniSans-Bold" }
                },
                [_vm._v(" stream on your favorite devices ")]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-2 md:grid-cols-3 mt-4 gap-4 md:gap-24 text-2xl text-center"
                },
                _vm._l(_vm.devices, function(device, i) {
                  return _c("div", { key: "device_" + i }, [
                    _c("img", {
                      staticClass: "w-full",
                      attrs: { src: device, alt: "on-demand-img" }
                    })
                  ])
                }),
                0
              )
            ]),
            _c("div", { staticClass: "my-10" }, [
              _c("div", { staticClass: "uppercase text-3xl text-center" }, [
                _vm._v(" frequently asked questions ")
              ]),
              _c(
                "div",
                { staticClass: " mt-4 flex flex-col gap-3" },
                _vm._l(_vm.faq, function(item, index) {
                  return _c("FrequentlyAskedQuestion", {
                    key: "faq_" + index,
                    attrs: { question: item.question, answer: item.answer }
                  })
                }),
                1
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }