import api from '.'
import { instance as axios } from '../plugins/axios'
import { settings } from '@/library/variables'

export const search = {
  async searchQuery(searchQuery) {
    try {
      return await axios.get(
        '/v2/properties/' + settings.propertyID + '/search?query=' + searchQuery
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async getPopularSearch() {
    try {
      return await axios.get(
        '/v2/properties/' + settings.propertyID + '/popular-search'
      )
    } catch (err) {
      return api.response.error(err)
    }
  }
}
