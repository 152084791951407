var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isSubscriber
    ? _c(
        "div",
        { staticClass: "banner_container" },
        [
          _c("router-link", { attrs: { to: _vm.currentBannerLink } }, [
            _c("img", {
              attrs: { src: _vm.currentBannerImage, alt: "top_banner" }
            })
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }