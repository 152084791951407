var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("header", { staticClass: "header hidden lg:block" }, [
      _c("div", { staticClass: "wrapper" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c(
              "div",
              { staticClass: "logo" },
              [
                _c(
                  "router-link",
                  { staticClass: "site-title", attrs: { to: "/" } },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/images/logo.png") }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._l(_vm.visibleNavLinks, function(category, name) {
              return _c("div", { key: name, staticClass: "list-category" }, [
                _c("div", { staticClass: "category-header" }, [
                  _c(
                    "a",
                    {
                      staticClass: "category title relative",
                      class: { active: _vm.isActive(category) },
                      on: {
                        click: function($event) {
                          return _vm.goTo(category.linkTo)
                        }
                      }
                    },
                    [
                      category.icon
                        ? _c("font-awesome-icon", {
                            staticStyle: { "margin-right": "-2px" },
                            attrs: { icon: category.icon }
                          })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(category.title) + " ")
                    ],
                    1
                  )
                ])
              ])
            }),
            _c("div", { staticClass: "input-search" }, [
              _vm._m(0),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchKeyword,
                    expression: "searchKeyword"
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: "Search for live events,  shows, news and more…"
                },
                domProps: { value: _vm.searchKeyword },
                on: {
                  change: _vm.handleSearch,
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.searchFocused = true
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchKeyword = $event.target.value
                  }
                }
              }),
              _vm.searchFocused && !_vm.searchKeyword
                ? _c(
                    "div",
                    { staticClass: "popular-search" },
                    _vm._l(_vm.popularSearches, function(item) {
                      return _c(
                        "p",
                        {
                          key: item,
                          staticClass: "popular-search__item",
                          on: {
                            mousedown: function($event) {
                              $event.stopPropagation()
                              return _vm.searchWithKeyword(item)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item) + " ")]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          ],
          2
        ),
        _c("div", { staticClass: "right" }, [
          _c(
            "a",
            {
              staticClass: "header-link",
              attrs: { href: "https://playpokergo.com/", target: "_blank" }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "icon",
                  staticStyle: { fill: "white", width: "17px" },
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 640 512"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M7.5 194.9c-15.4-26.6-6.3-60.7 20.4-76.1L220.7 7.5c26.6-15.4 60.7-6.3 76.1 20.4l167 289.3c15.4 26.6 6.2 60.7-20.4 76.1L250.5 504.5c-26.6 15.4-60.7 6.2-76.1-20.4L7.5 194.9zM197 159.6c-11.1-3-22.6 3.6-25.6 14.8l-7.2 26.9-20.4 76.1c-7 26 8.5 52.7 34.4 59.7s52.7-8.5 59.7-34.4l2.4-8.8c.1-.4 .2-.8 .3-1.1l17.7 30.7-12.1 7c-6.7 3.8-8.9 12.4-5.1 19s12.4 8.9 19 5.1l48.2-27.8c6.7-3.8 8.9-12.4 5.1-19s-12.4-8.9-19-5.1l-12.1 7-17.7-30.7c.4 .1 .8 .2 1.1 .3l8.8 2.4c26 7 52.7-8.5 59.7-34.4s-8.5-52.7-34.4-59.7l-76.1-20.4L197 159.6zM459.4 420.9c41.9-24.2 56.3-77.8 32.1-119.8L354.7 64.2c1.7-.2 3.5-.2 5.3-.2l224 0c30.9 0 56 25.1 56 56l0 336c0 30.9-25.1 56-56 56l-224 0c-13.7 0-26.2-4.9-35.9-13l135.3-78.1zm74.5-183.3L582 187.6c14-14.5 13.2-38.5-2.2-51.9c-14-11.7-34.5-9.5-46.9 3.2l-4.7 5.2-5-5.2c-12.5-12.7-33.2-15-46.7-3.2c-15.7 13.5-16.5 37.5-2.5 51.9l48.4 49.9c3 3.2 8.2 3.2 11.5 0z"
                    }
                  })
                ]
              ),
              _vm._v(" Play Now ")
            ]
          ),
          _vm._m(1),
          _c("div", { staticClass: "user-nav" }, [
            _c(
              "div",
              { staticClass: "btn-subscribe" },
              [
                !_vm.isPaidOrPending
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: _vm.loggedIn
                            ? "/select-plan"
                            : "/register/select-plan"
                        }
                      },
                      [_vm._v(" Subscribe ")]
                    )
                  : this.subscription.store === "stripe" &&
                    this.subscription.interval !== "training_annual"
                  ? _c(
                      "router-link",
                      { attrs: { to: "/account/change-plan/checkout" } },
                      [_vm._v(" Upgrade ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          !_vm.loggedIn
            ? _c(
                "div",
                {
                  staticClass: "header-link div-link",
                  on: { click: _vm.gotoLogin }
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "icon",
                    attrs: { icon: "right-to-bracket" }
                  }),
                  _vm._v(" Sign In ")
                ],
                1
              )
            : _vm._e(),
          _vm.loggedIn
            ? _c("div", { staticClass: "profile" }, [
                _c(
                  "ul",
                  {
                    staticClass: "navbar-nav ms-auto mb-2 mb-lg-0 profile-menu"
                  },
                  [
                    _c("li", { staticClass: "nav-item dropdown" }, [
                      _c(
                        "span",
                        {
                          staticClass: "nav-link dropdown-toggle",
                          attrs: {
                            id: "navbarDropdown",
                            role: "button",
                            "data-bs-toggle": "dropdown",
                            "aria-expanded": "false"
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../assets/images/account-icon.svg"),
                              width: "28"
                            }
                          }),
                          _vm.firstName != ""
                            ? _c("span", { staticClass: "name-profile" }, [
                                _vm._v(" Hi, " + _vm._s(_vm.firstName) + " ")
                              ])
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "ul",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { "aria-labelledby": "navbarDropdown" }
                        },
                        [
                          _c(
                            "li",
                            {
                              staticClass: "dropdown-item",
                              on: {
                                click: function($event) {
                                  return _vm.goTo("/account")
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-user-circle" }),
                              _c("span", [_vm._v("Profile")])
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "dropdown-item",
                              on: {
                                click: function($event) {
                                  return _vm.goTo("/account")
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "far fa-chart-bar" }),
                              _c("span", [_vm._v("Subscription")])
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "dropdown-item",
                              on: {
                                click: function($event) {
                                  return _vm.goTo("/my-list")
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-plus" }),
                              _c("span", [_vm._v("My List")])
                            ]
                          ),
                          _vm._m(2),
                          _c(
                            "li",
                            {
                              staticClass: "dropdown-item",
                              on: {
                                click: function($event) {
                                  return _vm.goTo("/logout")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-sign-out-alt fa-fw"
                              }),
                              _c("span", [_vm._v("Log Out")])
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            : _vm._e()
        ])
      ])
    ]),
    _c(
      "header",
      {
        staticClass: "header lg:hidden",
        class: { "training-header": this.$route.path === "/training" }
      },
      [
        _c("router-link", { staticClass: "logo-mobile", attrs: { to: "/" } }, [
          _c("div", { staticClass: "img" }, [
            _c("img", { attrs: { src: require("@/assets/images/logo.png") } })
          ])
        ]),
        this.$route.path === "/search"
          ? _c("div", { staticClass: "searchMobile" }, [
              _c("div", { staticClass: "searchInput" }, [
                _vm._m(3),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchKeyword,
                      expression: "searchKeyword"
                    }
                  ],
                  attrs: {
                    type: "text",
                    placeholder:
                      "Search for live events,  shows, news and more…"
                  },
                  domProps: { value: _vm.searchKeyword },
                  on: {
                    change: _vm.handleSearch,
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.searchFocused = true
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchKeyword = $event.target.value
                    }
                  }
                })
              ]),
              _vm.searchFocused
                ? _c(
                    "div",
                    { staticClass: "popular-search" },
                    _vm._l(_vm.popularSearches, function(item) {
                      return _c(
                        "p",
                        {
                          key: item,
                          staticClass: "popular-search__item",
                          on: {
                            mousedown: function($event) {
                              $event.stopPropagation()
                              return _vm.searchWithKeyword(item)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item) + " ")]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-icon" }, [
      _c("i", { staticClass: "fas fa-search" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "header-link",
        attrs: { href: "https://shop.pokergo.com/", target: "_blank" }
      },
      [
        _c("i", { staticClass: "fas fa-shopping-cart icon" }),
        _vm._v(" Shop Now ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("hr", { staticClass: "dropdown-divider" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-icon" }, [
      _c("i", { staticClass: "fas fa-search" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }