var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "container min-height-700" },
      [
        _c("Banner", { staticClass: "banner_class" }),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "search-wrapper" },
              [
                _c("loading-progress", {
                  attrs: {
                    progress: 60,
                    indeterminate: true,
                    "hide-background": true,
                    size: "50",
                    rotate: "",
                    fillDuration: "2",
                    rotationDuration: "1"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._l(_vm.entities, function(entity) {
          return _c("LongCard", {
            key: entity.id,
            attrs: {
              "entity-type": entity.type,
              "entity-id": entity.id,
              "entity-data": entity,
              "entity-lockbypass": true
            }
          })
        }),
        _vm.entities.length === 0 && _vm.searched
          ? _c("div", { staticClass: "no-results" }, [
              _c("p", [
                _vm._v(
                  "Bad Flop. Please check your spelling or try a new search."
                )
              ])
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }