import jwtDecode from 'jwt-decode'
import api from '@/api'
import { localstorage } from '../services/storage/localStorageService'
import { settings } from '../library/variables'

const { authentication } = api

function getDefaultState() {
  return {
    authenticated: false,
    paid: false,
    user: {},
    userEntitlements: [],
    email: '',
    username: '',
    isJoin: false,
    subscriberID: '',
    subscription: {
      interval: '',
      store: ''
    },
    isEmailVerified: false
  }
}

export default {
  state: getDefaultState(),
  mutations: {
    setUser(state, { user, userEntitlements }) {
      state.user = user
      state.userEntitlements = userEntitlements
    },
    setAuthenticated(state, value) {
      state.authenticated = true
      try {
        localstorage.setToken(value)
        let token = jwtDecode(value)

        state.email = token.email
        state.ITG_OFF = token.itg_off

        state.subscriberID = token.sub
        state.subscription.interval = token.subscription_level
        state.subscription.store = token.store

        window.userBroadcasterForeignID = token.sub
        window.userInitialName = token.user_name
        state.username = token.user_name
        state.paid =
          token.subscription_level && token.subscription_level !== 'free'

        state.isEmailVerified =
          token.email_verified !== undefined ? token.email_verified : true
      } catch (e) {
        console.log(e)
      }
    },
    resetUserStore(state) {
      Object.assign(state, getDefaultState())
    }
  },
  getters: {
    isAuthenticated(state) {
      return state.authenticated
    },
    user(state) {
      return state.user
    },
    firstName(state) {
      return state.user.basic?.first_name || ''
    },
    lastName(state) {
      return state.user.basic?.last_name || ''
    },
    getEmail(state) {
      return state.email
    },
    userSubscriberID(state) {
      return state.subscriberID
    },
    userEntitlements(state) {
      return state.userEntitlements
    },
    isSubscriber(state) {
      return state.paid
    },
    igtOff(state) {
      if (state.user.basic) {
        return state.user.basic?.itg_off || 0
      }
      return 1
    }
  },
  actions: {
    async REQUEST_LOGIN(state, payload) {
      const { email, password, code } = payload
      let response = await authentication.login(
        {
          email,
          password,
          code
        },
        settings.propertyID
      )

      if (response.status === 200) {
        state.commit('setAuthenticated', response.data.meta.token)
      } else if (response.status === 428) {
        state.commit('setAuthenticated', response.data.meta.token)
        setTimeout(async () => {
          await authentication.requestEmailCode()
        }, 500)
      }
      return response
    },
    async REQUEST_JOIN_GAME(state, payload) {
      const { email, age } = payload
      let response = await authentication.joinGame({
        email,
        age
      })

      return response
    },
    async REQUEST_SIGNUP(state, payload) {
      state.commit('checkoutControls', { loading: true, error: undefined })

      const response = await authentication.register(payload)
      if (response.status === 200) {
        state.commit('setAuthenticated', response.data.meta.token)
      } else if (response.status === 428) {
        state.commit('setAuthenticated', response.data.meta.token)
        setTimeout(async () => {
          await authentication.requestEmailCode()
        }, 500)
      }

      state.commit('checkoutControls', { loading: false })
      return response
    },
    FETCH_ME(state) {
      return authentication.fetchMe().then(({ data }) => {
        state.commit('setUser', {
          user: data.data,
          userEntitlements: data.data.external_product_ids
        })
      })
    }
  }
}
