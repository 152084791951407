import api from '.'
import { instance as axios, subscriberInstance } from '../plugins/axios'
import { settings } from '@/library/variables'

export const account = {
  async fetchTransactions() {
    try {
      return await axios.get(
        '/v2/properties/' + settings.propertyID + '/subscribers/me/transactions'
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async cancel() {
    try {
      return await axios.post(
        '/v2/properties/' +
          settings.propertyID +
          '/subscribers/me/purchase/cancel'
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async takeDiscount(interval) {
    try {
      const body = {
        interval
      }

      return await axios.post(
        '/v2/properties/' +
          settings.propertyID +
          '/subscribers/me/purchase/discount',
        body
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async saveNameChange(data) {
    try {
      return await axios.post(
        '/v2/properties/' + settings.propertyID + '/subscribers/me/change-name',
        {
          first_name: data.first_name,
          last_name: data.last_name,
          user_name: data.user_name,
          phone_number: data.phone_number,
          postal_code: data.postal_code,
          itg_off: data.itg_off ? 1 : 0
        },
        {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          progress: false
        }
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async savePassword(data) {
    try {
      return await subscriberInstance.post(
        '/properties/' +
          settings.propertyID +
          '/subscribers/me/change-password',
        JSON.stringify({
          //old_password: data.old_password,
          new_password: data.new_password,
          new_password_confirm: data.new_password_confirm
        })
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async resetPassword(data, type) {
    try {
      return await subscriberInstance.post(
        '/properties/' +
          settings.propertyID +
          '/subscribers/change-password?type=' +
          type,
        JSON.stringify({
          old_password: data.old_password,
          new_password: data.new_password,
          new_password_confirm: data.new_password_confirm
        })
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async submitCancelReason(payload) {
    try {
      return await subscriberInstance.post(
        '/v2/properties/' +
          settings.propertyID +
          '/subscribers/me/purchase/cancelreason',
        payload
      )
    } catch (err) {
      return api.response.error(err)
    }
  }
}
