<template>
  <div class="page">
    <div class="container min-height-700">
      <Banner class="banner_class" />
      <!--      <div>-->
      <!--        <SearchFilters />-->
      <!--      </div>-->
      <div v-if="loading" class="search-wrapper">
        <loading-progress
          :progress="60"
          :indeterminate="true"
          :hide-background="true"
          size="50"
          rotate
          fillDuration="2"
          rotationDuration="1"
        />
      </div>
      <LongCard
        v-for="entity in entities"
        :key="entity.id"
        :entity-type="entity.type"
        :entity-id="entity.id"
        :entity-data="entity"
        :entity-lockbypass="true"
      />
      <div v-if="entities.length === 0 && searched" class="no-results">
        <p>Bad Flop. Please check your spelling or try a new search.</p>
      </div>
    </div>
  </div>
</template>

<script>
import LongCard from '@/components/LongCard'
import { mapGetters, mapState } from 'vuex'
import Banner from '@/components/Banner.vue'

export default {
  name: 'Search',
  components: {
    Banner,
    LongCard
  },
  data() {
    return {
      entities: [],
      searchQuery: '',
      searched: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['getPropertyId', 'isSubscriber']),
    ...mapState({
      searchKeyword: (state) => state.other.searchKeyword,
      subscription: (state) => state.user.subscription
    }),
    property() {
      return this.getPropertyId
    }
  },
  async mounted() {
    const query = this.$route.query.q
    if (query) {
      this.$store.commit('setSearchKeyword', query)
    }
    this.searchQuery = this.searchKeyword
  },
  methods: {
    async search() {
      this.searched = false
      this.loading = true
      if (this.searchQuery.length < 3) {
        this.entities = []
        this.searched = true
        this.loading = false
        return
      }
      this.entities = []
      const response = await this.$store.dispatch(
        'search/SearchQuery',
        this.searchQuery
      )
      this.searched = true
      this.loading = false

      dataLayer.push({
        event: 'pageview',
        page_title: 'search',
        page_location: 'search',
        page_path: '/search?q=' + this.searchQuery
      })

      if (response.status === 200) {
        const videos = response.data.data.videos?.videos || []
        videos.forEach((v) => ((v.video_type = v.type), (v.type = 'video')))
        const collections = response.data.data.collections || []
        collections.forEach(
          (c) => ((c.collection_type = c.type), (c.type = 'collection'))
        )
        this.entities = [...collections, ...videos]
      }
    }
  },
  watch: {
    searchQuery() {
      if (this.searchQuery == '') {
        this.searched = false
        this.entities = []
      } else {
        this.search()
      }
    },
    searchKeyword() {
      this.searchQuery = this.searchKeyword
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.fullPath !== '/search') {
      this.$store.commit('setSearchKeyword', '')
    }
    next()
  }
}
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  padding: 0 20px 20px 20px;
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .container {
    margin-top: 8rem;
  }
}
@media screen and (max-width: 480px) {
  .container {
    margin-top: 10rem;
  }
}

.search-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-results {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
  .two-buttons {
    margin: 1rem;
    width: 250px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    height: 40px;
    line-height: 35px;
    display: flex;
    flex-direction: row;
    button {
      justify-content: center;
      display: flex;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      padding-top: 1px;
      align-items: center;
      cursor: pointer;
      width: 50%;
      height: 100%;
      border: none !important;
      background-color: transparent;
      color: #ffffff;
      .icon {
        height: 22px;
        width: 22px;
        margin-right: 8px;
        &.grid {
          background: url('../../assets/images/grid-icon-light.svg') no-repeat;
        }
        &.list {
          background: url('../../assets/images/list-icon-dark.svg') no-repeat;
        }
      }
      &.active {
        background-color: $poker-accent;
      }
      &:focus {
        outline: none !important;
      }
    }
  }
}

.banner_class {
  margin-top: 0px;
  margin-bottom: 35px;
  @media (max-width: 971px) {
    margin-top: 0px;
  }
}
</style>
