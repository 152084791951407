<template>
  <div>
    <!--    <PokergoplayOverlay />-->

    <component v-bind:is="layout" :class="tabCustom">
      <router-view @authenticated="setAuthenticated" />
    </component>

    <div class="bottom-bar">
      <ul>
        <li>
          <router-link to="/">
            <i class="fas fa-home"></i>
            <span>Home</span>
          </router-link>
        </li>
        <li>
          <router-link to="/on-demand">
            <i class="fas fa-play"></i>
            <span>On Demand</span>
          </router-link>
        </li>
        <li>
          <router-link to="/schedule">
            <i class="fas fa-calendar-alt"></i>
            <span>Schedule</span>
          </router-link>
        </li>
        <li>
          <router-link to="/training">
            <i class="fas fa-graduation-cap"></i>
            <span>PokerU</span>
          </router-link>
        </li>
        <li>
          <router-link to="/options">
            <i class="fas fa-bars"></i>
            <span>More</span>
          </router-link>
        </li>
      </ul>
    </div>
    <portal-target name="modal-outlet" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import DefaultLayout from './layouts/default.vue'
import EmbedLayout from './layouts/embed.vue'
import DefaultNoFooter from './layouts/defaultNoFooter.vue'
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import LoginLayout from './layouts/login.vue'
import RegisterLayout from './layouts/register.vue'
import { PortalTarget } from 'portal-vue'

import { localstorage } from '@/services/storage/localStorageService'
import { General } from './library/general'
import PokergoplayOverlay from './components/PokergoplayOverlay'
require('./plugins')

export default {
  name: 'App',
  components: {
    PokergoplayOverlay,
    DefaultLayout,
    EmbedLayout,
    DefaultNoFooter,
    Footer,
    Header,
    LoginLayout,
    RegisterLayout,
    PortalTarget
  },
  data: () => ({
    timer: null,
    windowWidth: 900
  }),
  computed: {
    ...mapGetters(['isAuthenticated', 'isSubscriber']),
    register() {
      return this.$route.path === '/register'
    },
    layout() {
      return this.$route.meta.layout || 'default-layout'
    },
    tabCustom() {
      if (this.$route.path === '/training') {
        return 'bg-training'
      } else {
        return 'tab'
      }
    },
    isMobile() {
      return this.windowWidth <= 1024
    }
  },
  watch: {
    isMobile: {
      handler(newVal) {
        if (newVal) {
          this.removeSupportBtn()
        } else {
          const supportEl = document.querySelector('#freshworks-container')
          if (supportEl) supportEl.style.display = 'block'
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (this.$store.state.user.email) {
      window.profitwell('start', { user_email: this.$store.state.user.email })
    } else {
      window.profitwell('start', {})
    }

    this.windowWidth = window.innerWidth

    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    })

    setTimeout(() => {
      console.log(
        "          _____\n         |2 .  | _____\n         | /.\\ ||3 ^  | _____\n         |(_._)|| / \\ ||8 _  | _____\n         |  |  || \\ / || ( ) ||4_ _ |\n         |____V||  .  ||(_'_)||( v )|\n                |____V||  |  || \\ / |\n                       |____V||  .  |\n                              |____V|\nYou just flopped. Stop peaking here and get back to watching Poker Videos!\nAny security concerns or reports send an email directly to support@pokergo.com\n"
      )
    }, 5000)
  },
  methods: {
    ...mapMutations(['setAuthenticated']),
    logout() {
      localstorage.clearAuth()
      this.$router.push({ name: 'HomeView' })
      window.location.reload()
    },
    removeSupportBtn() {
      this.timer = setInterval(() => {
        const supportEl = document.querySelector('#freshworks-container')
        if (supportEl) {
          supportEl.style.display = 'none'
          clearInterval(this.timer)
        }
      }, 100)
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/app.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}
.bottom-bar {
  visibility: hidden;
}

@media screen and (max-width: 1025px) {
  .bottom-bar {
    width: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    background: #141414;
    visibility: visible;
    border-top: 1px solid #5b5b5b;

    ul {
      height: 60px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0;
      padding: 0px;
      li {
        list-style: none;

        a {
          display: flex;
          flex-direction: column;
          color: gray;
          i {
            text-align: center;
            margin-bottom: 5px;
            font-size: 20px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>

<style src="video.js/dist/video-js.css"></style>
<style>
@import url('https://fonts.googleapis.com/css?family=Lato|Open+Sans&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
.tab {
  position: absolute;
  width: 100%;
  background: url('./assets/images/Background@2x.png') no-repeat;
  background-size: contain;
}
.bg-training {
  position: absolute;
  width: 100%;
  background: url('./assets/images/training/bg-hero.png') no-repeat;
  background-size: contain;
}
.bgNotAuthenticated {
  background: url('./assets/images/Background@2x.png') no-repeat;
  backdrop-filter: blur(5px);
  background-size: 100%;
}
.router-link-exact-active {
  color: #ffffff !important;
}
@media screen and (max-width: 768px) {
  .page {
    margin-top: 83px !important;
  }
}
</style>
