authentication / api

import api from '.'
import {
  instance,
  instanceEvent,
  subscriberInstance,
  gameMailingList
} from '../plugins/axios'
import { settings } from '@/library/variables'

export const authentication = {
  async login(data) {
    try {
      return await subscriberInstance.post(
        `/v3/api/properties/` + settings.propertyID + '/sign-in',
        JSON.stringify({
          data: {
            type: 'subscriber',
            attributes: {
              code: data.code
            }
          }
        }),
        {
          headers: {
            Authorization:
              'Basic ' +
              Buffer.from(data.email + ':' + data.password).toString('base64')
          }
        }
      )
    } catch (err) {
      console.log(err)
      return api.response.error(err)
    }
  },
  async reauth() {
    try {
      return await subscriberInstance.post(
        `/properties/` + settings.propertyID + '/reauth'
      )
    } catch (err) {
      console.log(err)
      return api.response.error(err)
    }
  },
  async postEvent(data) {
    try {
      return await instanceEvent.post(
        'events',
        {
          data: {
            type: 'subscriber',
            attributes: {
              viewer: data.viewer,
              sessionId: data.sessionId,
              type: 'event',
              action: 'app:init'
            }
          }
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
    } catch (err) {
      return api.response.error(err)
    }
  },

  // async register(user, first, last, property, pass, age) {
  async register(data) {
    try {
      return await subscriberInstance.post(
        `/v3/api/properties/` + settings.propertyID + '/register',
        JSON.stringify({
          data: {
            type: 'subscriber',
            attributes: {
              email: data.email,
              first_name: data.first_name,
              last_name: data.last_name,
              property_id: data.property_id,
              password: data.password,
              code: data.code,
              recapture: data.recapture
            }
          }
        })
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async postEventRegister(res) {
    try {
      return await instanceEvent.post(
        `/events`,
        {
          data: {
            attributes: {
              userId: res.data.id,
              type: 'event',
              action: 'app:newId'
            }
          }
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async forgotPassword(user, usertype) {
    try {
      if (usertype == undefined) {
        return await instance.post(
          '/v2/properties/' +
            settings.propertyID +
            '/subscribers/reset-password?email=' +
            encodeURIComponent(user)
        )
      } else {
        return await instance.post(
          '/v2/properties/' +
            settings.propertyID +
            '/subscribers/reset-password?email=' +
            encodeURIComponent(user) +
            '&type=' +
            usertype
        )
      }
    } catch (err) {
      return api.response.error(err)
    }
  },
  async fetchMe() {
    return await instance.get(
      '/v2/properties/' + settings.propertyID + '/subscribers/me'
    )
  },
  async joinGame(payload) {
    try {
      return await gameMailingList.post(
        'eb42f1eddb4acfd2522ba8de5bbb8593.m.pipedream.net',
        payload
      )
    } catch (err) {
      return api.response.error(err)
    }
  },
  async requestEmailCode() {
    try {
      return await instance.get(
        `/v2/properties/` +
          settings.propertyID +
          '/subscribers/me/requestemailcode'
      )
    } catch (err) {
      return api.response.error(err)
    }
  },

  async validateEmailCode(payload) {
    try {
      return await instance.post(
        `/v2/properties/` +
          settings.propertyID +
          '/subscribers/me/validateemailcode',
        payload
      )
    } catch (err) {
      return api.response.error(err)
    }
  }
}
